export const New = () => {
  return (
    <div className="new">
      <p className="new__title">কি খবর?</p>
      <p className="new__description">
        বোনাস এবং প্রচার বিভাগ আপডেট করা হয়েছে.
      </p>
      <p className="new__description">
        নিকটতম শাখা, এটিএম বা টার্মিনালের অবস্থান মানচিত্রে যোগ করা হয়েছে।
      </p>
      <p className="new__description">
        ফেস আইডি এবং টাচ আইডি সমর্থন যোগ করা হয়েছে।
      </p>
      <p className="new__description">
        কিছু অ্যান্ড্রয়েড 10 ব্যবহারকারীর সম্মুখীন হওয়া পুশ বিজ্ঞপ্তিগুলির
        সাথে একটি সমস্যা সমাধান করা হয়েছে।
      </p>
      <p className="new__description">
        অ্যান্ড্রয়েড ব্যবহারকারীদের জন্য সেটিংসে পাঠ্যটি খুব ছোট ছিল এমন একটি
        সমস্যা সমাধান করা হয়েছে৷
      </p>
    </div>
  );
};
